<template>
  <form @submit.prevent="createLeave" class="relative">
    <div
      v-show="isBusy"
      class="absolute inset-0 bg-faint-white flex items-center justify-center z-20"
    >
      <spinner :color="'text-black'" :size="10" />
    </div>
    <div class="mb-2">
      <label class="uppercase font-semibold text-sm"
        >Staff
        <span class="text-red-500">*</span>
      </label>
      <div
        class="fixed inset-0 overflow-hidden z-10"
        v-if="staffSelectOpen"
        @click="staffSelectOpen = false"
      ></div>
      <div class="relative mx-3">
        <div class="relative -mx-3">
          <input
            readonly
            @click="openSearchStaff"
            v-model="staffName"
            class="w-full bge-input py-2 pl-4 pr-10 rounded cursor-pointer"
            type="text"
          />
          <button
            type="button"
            v-if="staffName"
            name="Clear Supplier"
            @click="clearStaff"
            class="m-1 z-10 text-red-500 hover:bg-red-200 rounded-full absolute inset-y-0 right-0 flex items-center px-2 transition-colors duration-150 ease-in-out"
          >
            <svg
              class="stroke-current h-5 w-5"
              fill="none"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              viewBox="0 0 24 24"
            >
              <path d="M18 6L6 18M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div
          v-if="staffSelectOpen"
          class="z-10 absolute w-full max-h-5vh bg-white shadow-lg rounded border border-gray-300 top-0 mt-5 p-2"
        >
          <div class="relative flex flex-col">
            <input
              ref="tbStaffSearch"
              type="text"
              v-model="staffSearchTerm"
              @keydown="searchStaff"
              class="bge-input bge-input-spacing rounded"
              placeholder="Staff name (enter 3 letters or more to search)"
            />
            <div class="flex flex-col overflow-y-scroll bg-white mt-2">
              <template v-for="result in staffResults">
                <button
                  type="button"
                  :key="result.staff_id"
                  @click="selectStaff(result)"
                  class="hover:bg-orange-200 flex items-center py-2 transition duration-100 focus:bg-orange-200 text-left px-2"
                >
                  <span class="ml-1">
                    {{ result.first_name }}
                  </span>
                </button>
              </template>
              <p class="mx-auto py-4 px-8" v-if="staffResults == ''">
                No staff match your search
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full mb-2">
      <label class="uppercase font-semibold text-sm"
        >Year<span class="text-red-400">*</span></label
      >
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="leaveYear"
        minimum-view="year"
        format="yyyy"
        @closed="yearClosed"
      ></datepicker>
    </div>

    <div class="w-full py-2" v-show="showApplyDefault">
      <label class="w-full flex items-center uppercase font-semibold text-sm">
        <input
          class="h-5 w-5 form-checkbox focus:shadow-none text-primary focus:border-primary mr-4"
          v-model="applyDefault"
          type="checkbox"
          v-on:change="applyDefaultClick()"
        />
        <span>Apply Default leave balance</span>
      </label>
    </div>

    <div class="w-full mb-2" v-show="showApplyDefault">
      <label class="uppercase font-semibold text-sm">
        Leave allowance type
        <span class="text-red-500">*</span>
      </label>
      <div class="w-full relative">
        <select
          class="bge-input bge-select rounded"
          v-model="selectedLeaveAllowanceType"
          @change="changeAllowanceType"
        >
          <option
            v-for="item in formattedLeaveAllowances"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </option>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
      </div>
    </div>

    <div class="flex flex-col justify-center pt-4">
      <div class="flex items-center mb-5">
        <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
          Available Balances
        </h3>
        <hr class="flex-1 border-primary ml-4" />
      </div>

      <div v-show="allLeaveBalanceYear">
        <div class="vgt-wrap">
          <div class="vgt-inner-wrap">
            <div class="vgt-fixed-header"></div>
            <div class="vgt-responsive">
              <table class="vgt-table condensed">
                <thead>
                  <tr>
                    <th
                      class="vgt-left-align"
                      style="min-width: auto; width: auto"
                    >
                      <span>Leave Type</span>
                    </th>
                    <th
                      class="vgt-right-align"
                      style="min-width: auto; width: auto"
                    >
                      <span>Total Days</span>
                    </th>
                    <th
                      class="vgt-right-align"
                      style="min-width: auto; width: auto"
                    >
                      <span>Available Balance Days </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="balance in allLeaveBalanceYear"
                    :key="balance.balance_id"
                  >
                    <td class="vgt-left-align">
                      <span> {{ balance.leave_type }} </span>
                    </td>
                    <td class="vgt-right-align">
                      <span>
                        <input
                          class="bge-input bge-input-spacing rounded"
                          :value="balance.total_days"
                          @input="updateTotalDays(balance, $event)"
                          type="number"
                          min="0"
                          max="365"
                      /></span>
                    </td>
                    <td class="vgt-right-align">
                      <span>
                        <input
                          class="bge-input bge-input-spacing rounded"
                          disabled
                          v-model="balance.available_days"
                          type="number"
                          min="0"
                          max="30"
                      /></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="relative flex justify-end mt-5">
      <button
        type="submit"
        class="flex items-center bg-blue-200 hover:bg-blue-300 text-secondary py-1 px-3 rounded border border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Save</span>
      </button>
    </div>
  </form>
</template>

<script>
const Spinner = () => import("@/components/Spinner.vue");
const Notification = () => import("@/components/Notification.vue");
const Toggle = () => import("@/components/Toggle.vue");
const Datepicker = () => import("vuejs-datepicker");
import { LeaveAllowance } from "@/utils/constants";

export default {
  name: "CreateSetting",
  components: {
    Spinner,
    Toggle,
    Datepicker,
  },
  props: {
    staff: {
      type: Object,
    },
  },
  data() {
    return {
      isBusy: false,
      allLeaveBalanceYear: [],
      availableLeaves: [],
      staffName: "",
      staffSearchTerm: "",
      staffResults: [],
      staffSelectOpen: false,
      leaveYear: "",
      selectedStaff: {},
      applyDefault: false,
      showApplyDefault: false,
      selectedLeaveAllowanceType: "",
      formattedLeaveAllowances: [],

      columns: [
        {
          label: "Leave Type",
          field: "leave_type",
        },
        {
          label: "Total Days",
          field: "total_days",
          type: "number",
        },
        {
          label: "Available Balance Days ",
          field: "balance_days",
          type: "number",
        },
      ],
    };
  },
  mounted() {
    this.formattedLeaveAllowances = Object.keys(LeaveAllowance).map((key) => ({
      value: key,
      text: this.formatLeaveType(key),
    }));
    if (this.staff) {
      this.selectStaff(this.staff);
    }
  },
  computed: {},
  methods: {
    updateTotalDays(balance, event) {
      const newValue = parseInt(event.target.value) || 0;
      const change = newValue - balance.total_days;
      balance.total_days = newValue;
      balance.available_days += change;

      // Ensure available_days does not exceed its max or go below its min
      balance.available_days = Math.min(
        30,
        Math.max(0, balance.available_days),
      );
    },

    changeAllowanceType() {
      if (this.applyDefault) {
        const year = this.leaveYear.getFullYear().toString();
        this.allLeaveBalanceYear =
          LeaveAllowance[this.selectedLeaveAllowanceType];
        this.allLeaveBalanceYear.forEach(function (element) {
          element.year = year;
        });
      } else {
        this.allLeaveBalanceYear = [];
      }
    },
    formatLeaveType(key) {
      switch (key) {
        case "full_time":
          return "Full Time";
        case "part_time_4":
          return "Part Time (4 days/wk)";
        case "part_time_3":
          return "Part Time (3 days/wk)";
        default:
          return key; // or any default formatting you want
      }
    },
    applyDefaultClick() {},
    yearClosed() {
      const year = this.leaveYear.getFullYear();
      if (this.selectedStaff.staff_id) {
        this.allLeaveBalanceYear = this.selectedStaff.leave_balance.filter(
          (bal) => {
            return bal.year == year;
          },
        );
        if (!this.allLeaveBalanceYear || this.allLeaveBalanceYear.length == 0) {
          this.showApplyDefault = true;
        } else {
          this.showApplyDefault = false;
          this.applyDefault = false;
        }
      } else {
        this.allLeaveBalanceYear = [];
        this.showApplyDefault = false;
      }
    },

    onCellClick(params) {
      // params.row - row object
      // params.column - column object
      // params.rowIndex - index of this row on the current page.
      // params.event - click event
    },
    openSearchStaff: function () {
      this.staffSearchTerm = "";
      this.staffResults = [];
      this.staffSelectOpen = true;
      this.$nextTick(function () {
        this.$refs.tbStaffSearch.focus();
      });
    },
    searchStaff: _.debounce(async function () {
      this.staffResults = [];
      if (this.staffSearchTerm != "" && this.staffSearchTerm.length >= 3) {
        let results = await this.HolidayService.searchStaff(
          this.staffSearchTerm,
          { select: "staff_id,first_name,last_name,leave_balance" },
        );
        this.staffResults = results.data;

        this.$forceUpdate();
      }
    }, 500),
    selectStaff: function (staff) {
      this.selectedStaff = staff;
      this.staffName = staff.first_name;
      this.staffSelectOpen = false;
    },
    clearStaff: function () {
      this.allLeaveBalanceYear = [];
      this.selectedStaff = {};
      this.staff = {};
      this.staffName = "";
      this.leaveYear = "";
      this.$forceUpdate();
      this.showApplyDefault = false;
      this.applyDefault = false;
    },
    createLeave: async function () {
      let msg;
      let leaveBalance = this.allLeaveBalanceYear;
      let isUpdate = false;

      if (!this.selectedStaff.staff_id) {
        msg = `Please select staff`;
      } else if (!this.leaveYear) {
        msg = `Please select year`;
      } else if (!leaveBalance || leaveBalance.length <= 0) {
        msg = `Please add leave balance`;
      } else {
        leaveBalance.forEach((d) => {
          if (d.balance_id) {
            isUpdate = true;
          }
          if (d.balance_days < 0 || d.balance_days > 30) {
            msg = `Please enter valid leave balance for ${d.leave_type}`;
            return;
          }
        });
      }

      if (msg) {
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "warning",
                  title: "Error",
                  close: onClose,
                },
              },
              msg,
            );
          },
          {
            position: "top-right",
          },
        );
      }
      this.isBusy = true;
      try {
        if (isUpdate) {
          await this.HolidayService.updateStaffLeaveBalance(
            this.selectedStaff.staff_id,
            leaveBalance,
          );
        } else {
          await this.HolidayService.createStaffLeaveBalance(
            this.selectedStaff.staff_id,
            leaveBalance,
          );
        }
        this.clearStaff();
        this.isBusy = false;
        this.$emit("complete");
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Success",
                  close: onClose,
                },
              },
              "Leave has been created successfully.",
            );
          },
          {
            position: "top-right",
          },
        );
      } catch (error) {
        console.error(error);
        this.isBusy = false;
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "There's been an error whilst creating Leave.",
            );
          },
          {
            position: "top-right",
          },
        );
      }
    },
  },
};
</script>
